import Vue from "vue";
import App from "./App.vue";
import { routes } from './router/routes'
import store from "./store";
import VueRouter from 'vue-router'

Vue.use(VueRouter);
Vue.config.productionTip = false;

const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");
