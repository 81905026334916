<template>
  <div class="coluna">
    <div class="senha" id="password-notification">
      <h2>SENHA</h2>
      <div v-show="status === 'Chamando'" class="texto-senha senha-alert">
        {{ password.toUpperCase() }}
      </div>
    </div>
    <div class="guiche">
      <h2>GUICHÊ</h2>
      <div v-show="status === 'Chamando'" class="texto-senha">
        {{ guicheName.toUpperCase() }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "animate.css";

export default Vue.extend({
  name: "PasswordDisplay",
  props: ["password", "guicheName", "status"],
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.coluna {
  flex-direction: column;
}

.senha {
  height: 200px;
  width: 450px;
  padding: 20px;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #c4d7ed;
  border-radius: 15px 15px;
  font-family: "Nunito", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #4f4f4f;
  box-shadow: 1px 1px 1px #bfbfbf;
}

.guiche {
  height: 200px;
  width: 450px;
  padding: 20px;
  margin-left: 10px;
  margin-top: 22px;
  background-color: #c4d7ed;
  border-radius: 15px 15px;
  font-family: "Nunito", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #4f4f4f;
  box-shadow: 1px 1px 1px #bfbfbf;
}

.texto-senha {
  font-family: "Roboto Mono", monospace;
  margin-top: 35px;
  font-size: 60px;
  color: #363636;
}

.senha-alert {
  color: #fff0;
  animation: brilho 1s cubic-bezier(0.86, 0.01, 0.07, 0.76) infinite;
}

@keyframes brilho {
  50% {
    // color: rgb(0, 4, 255) text-shadow 0 0 12px var(--colorPurple),
    //   0 0 50px var(--colorPurple), 0 0 100px var(----colorPurple);
    color: blue;
  }
}
</style>
