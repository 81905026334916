<template>
  <div class="main" allow="autoplay">
    <meta http-equiv="refresh" content="900" />
    <br />
    <div class="image">
      <img src="../assets/cassems-logo.png" />
    </div>

    <div class="container">
      <PasswordDisplay
        :guicheName="guichePasswordCallout()"
        :password="passwordCallout()"
        :status="this.status"
      />
      <LastCalled :latestCalls="this.latestCalls" :status="this.status" />
    </div>

    <div>
      <div class="footer">
        <div class="texto-roda-pe" v-if="absentAttendace === true">
          Senha
          <b>"{{ passwordAbsent[passwordAbsent.length - 1] }}"</b>
          não compareceu ao guichê e voltou para fila.
          <b> Aguarde ser chamado.</b>
        </div>

        <div
          class="texto-roda-pe"
          v-else-if="lastAttendanceCanceled.status === 'Cancelado'"
        >
          Senha
          <b class="cancel">"{{ lastAttendanceCanceled.password }}"</b> não
          compareceu e seu atendimento foi <b class="cancel">CANCELADO.</b>
        </div>

        <div class="texto-roda-pe" v-else>
          CASSEMS - Sempre a frente, cuidando de você!
        </div>
      </div>
      <div class="data-horario">
        <div class="texto-horario">{{ date }}</div>
        <div class="texto-data">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PasswordDisplay from "@/components/PasswordDisplay.vue"; // @ is an alias to /src
import LastCalled from "@/components/LastCalled.vue";
import { api, socket } from "../services/api-connection";
import callSound from "../assets/callSound.wav";
import canceledSound from "../assets/canceledSound.wav";
import { AttendanceInQueueDTO } from "@/entities/attendanceInQueue-dto";

export default Vue.extend({
  name: "Panel",
  components: {
    PasswordDisplay,
    LastCalled,
  },

  data() {
    return {
      lastAttendanceInProgress: new AttendanceInQueueDTO(),
      lastAttendanceCanceled: new AttendanceInQueueDTO(),
      latestCalls: [new AttendanceInQueueDTO()],
      date: "",
      time: "",
      status: "",
      absentAttendace: false,
      passwordAbsent: [],
      idInterval: 0,
      idsTimeOut: [],
    };
  },

  mounted() {
    api.get("/attendance/in-attendance-list").then((res) => {
      const attendances = res.data.map((attendance) => {
        const guicheName = attendance.attendantInfo.find((attendant) => {
          return attendant.attendanceStatus === "chamando";
        });
        return {
          password: attendance.password,
          guicheCaller: guicheName.guicheCaller,
          status: attendance.status,
          attendance: { type: attendance.attendance.type },
        };
      });
      this.latestCalls = attendances;
      if (this.latestCalls.length > 0) {
        this.playCallSound(true);
      }
    });
  },

  created() {
    socket.on("connection", (response) =>
      response
        ? console.log(response)
        : console.log("Erro ao conectar com o socket da api!")
    );

    socket.on("attendanceCanceled", (res) => {
      this.playCallSound(false);
      this.playCanceledSound(canceledSound);
      this.canceledAttendanceUpdate(res);
    });

    socket.on("attendanceInProgress", (res) => {
      this.inProgressAttendanceUpdate(res);
      this.thereIsAttendanceInCallerCheck()
    });

    socket.on("attendanceCall", (res) => {
      this.removeDuplicatedAttendanceFromList(res);
      this.animateNotification();
      this.playCallSound(false);
      this.playCallSound(true);
      this.addAttendanceInList(res);
    });

    socket.on("passwordsRegression", (res) => {
	    this.regressedPasswords(res);
    });

    socket.on("absentOnFirstCall", (res) => {
      this.absentOnFirstCall(res);
      this.playCallSound(false);
    });

    setInterval(() => {
      this.getNow();
    }, 1000);
  },

  methods: {
    regressedPasswords(passwords) {
      for (let i = 0; i < passwords.length; i++) {
        for (let j = 0; j < this.latestCalls.length; j++) {
          if (this.latestCalls[j].password === passwords[i]) {
            this.latestCalls.splice(j, 1);
          }
        }
      }

      this.thereIsAttendanceInCallerCheck()
    },

    thereIsAttendanceInCallerCheck() {
      let isThereAttendanceOnCall = false;

      for (let i = 0; i < this.latestCalls.length; i++) {
        if (this.latestCalls[i].status === "Chamando") {
          isThereAttendanceOnCall = true;
          break;
        }
      }

      if (isThereAttendanceOnCall) {
        this.playCallSound(false);
        this.playCallSound(true);
      } else {
        this.playCallSound(false);
      }
    },

    absentOnFirstCall(password) {
      const index = this.latestCalls.findIndex((attendance) => {
        return attendance.password === password;
      });
      this.absentAttendace = true;
      this.latestCalls[index].status = "Ausente";
      this.passwordAbsent.push(password);
    },

    passwordCallout() {
      if (this.latestCalls.length > 0) {
        for (let i = 0; i < this.latestCalls.length; i++) {
          if (this.latestCalls[i].status === "Chamando") {
            this.status = this.latestCalls[i].status;
            return this.latestCalls[i].password;
          }
        }
      }
      return "";
    },

    guichePasswordCallout() {
      if (this.latestCalls.length > 0) {
        for (let i = 0; i < this.latestCalls.length; i++) {
          if (this.latestCalls[i].status === "Chamando") {
            return this.latestCalls[i].guicheCaller;
          }
        }
      }
      return "";
    },

    removeDuplicatedAttendanceFromList(attendance) {
      const index = this.latestCalls.findIndex((att) => {
        return att.password === attendance.password;
      });

      const attendanceDoesNotExistInArrayEnum = -1;

      if (index !== attendanceDoesNotExistInArrayEnum) {
        this.latestCalls.splice(index, 1);
      }
    },

    addAttendanceInList(attendance) {
      const guicheName = attendance.attendantInfo.find((attendant) => {
        return attendant.attendanceStatus === "chamando";
      });

      const newAttendance = {
        password: attendance.password,
        guicheCaller: guicheName.guicheCaller,
        status: attendance.status,
        attendance: { type: attendance.attendance.type },
      };

      this.latestCalls.unshift(newAttendance);

      this.latestCalls.forEach((call) => {
        if (call.password === attendance.password) {
          call.status = "Chamando";
        }
      });
    },

    canceledAttendanceUpdate(attendance) {
      this.lastAttendanceCanceled = attendance;

      for (let i = 0; i < this.passwordAbsent.length; i++) {
        if (this.passwordAbsent[i] === this.lastAttendanceCanceled.password) {
          this.absentAttendace = false;
          this.passwordAbsent.splice(i, 1);
          break;
        }
      }

      this.latestCalls.forEach((call) => {
        if (call.password === attendance.password) {
          call.status = "Cancelado";
        }
      });

      
      setTimeout(() => {
        const index = this.latestCalls.findIndex((attendanceInd) => {
          return attendanceInd.password === attendance.password;
        });
        this.latestCalls.splice(index, 1);
      }, 300000);
    },

    inProgressAttendanceUpdate(attendance) {
      this.lastAttendanceInProgress = attendance;

      if (
        this.passwordAbsent[this.passwordAbsent.length - 1] ===
        attendance.password
      ) {
        this.absentAttendace = false;
        this.lastAttendanceCanceled = new AttendanceInQueueDTO();
      }

      for (let i = 0; i < this.passwordAbsent.length; i++) {
        if (this.passwordAbsent[i] === this.lastAttendanceCanceled.password) {
          this.passwordAbsent.splice(i, 1);
          break;
        }
      }

      this.latestCalls.forEach((call) => {
        if (call.password === attendance.password) {
          call.status = "Em atendimento";
        }
      });

      
      setTimeout(() => {
        const index = this.latestCalls.findIndex((attendanceInd) => {
          return attendanceInd.password === attendance.password;
        });
        this.latestCalls.splice(index, 1);
      }, 10000);
    },

    playCallSound(play) {
      const audio = new Audio(callSound);
      if (play) {
        audio.play();
        this.idInterval = setInterval(() => {
          audio.play();
        }, 2000);
      } else {
        clearInterval(this.idInterval);
      }
    },
    playCanceledSound(sound) {
      const audio = new Audio(sound);
      audio.play();
    },

    animateNotification() {
      let password = document.getElementsByClassName("texto-senha");
      const divsInPasswordDisplay = 2;
      for (let i = 0; i < divsInPasswordDisplay; i++) {
        password[i].classList.add("animate__heartBeat");
        setInterval(() => {
          password[i].classList.remove("animate__heartBeat");
        }, 2500);
      }
    },

    getNow() {
      const today = new Date();
      const date =
        String(today.getDate()).padStart(2, "0") +
        "/" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "/" +
        today.getFullYear();
      const time =
        String(today.getHours()).padStart(2, "0") +
        ":" +
        String(today.getMinutes()).padStart(2, "0") +
        ":" +
        String(today.getSeconds()).padStart(2, "0");
      this.date = date;
      this.time = time;
    },
  },
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap");
@import "../sass/style.scss";

#app {
  animation: gradient 10s ease infinite;
  background: linear-gradient(-45deg, #c7c4c4, #6598b8, #c7c4c4) no-repeat;
  background-size: 300% 300%;
  display: flex;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100vw;
  height: 50px;
  background-color: #6598b8;
  font-weight: bold;
}

.container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.image {
  margin: 0 auto;
  margin-bottom: 20px;
}

.texto-roda-pe {
  font-family: "Nunito", "Sans-Serif";
  font-size: 28px;
  color: #f2f2f2;
  width: 1200px;
  height: 30px;
  border-radius: 4px;
  margin-left: 110px;
  font-weight: bold;
}

.absent {
  color: #ffffff;
  width: 1500px;
}

.cancel {
  color: #a70101;

  width: 1500px;
}
.data-horario {
  position: absolute;
  margin-bottom: 0;
  bottom: 0;
  height: 50px;
  width: 10vw;
  background-color: #c4d7ed;
  border-top-right-radius: 8px;
}

.texto-horario {
  margin-top: 10px;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
}

.texto-data {
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
}
</style>
