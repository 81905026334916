// DTO PARA EVITAR 'vue warn' DE TYPE ERROR //

export class QueueDTO {
  readonly count = '';
  readonly type = '';
  readonly id = '';
  readonly runningTime = '';
  readonly classification = '';
  readonly prefixCharacter = '';
  readonly guiche = {
    name: ''
  }

  constructor() { }
}

export class AttendanceInQueueDTO {
  readonly attendance = '';
  readonly queue = [new QueueDTO()];
  readonly status = '';
  readonly password = '';
  readonly guicheCaller = '';

  constructor() { }
}