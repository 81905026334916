<template>
  <div class="fundo">
    <br />
    <div class="texto-chamado">ÚLTIMOS CHAMADOS</div>
    <br />
    <div>
      <div class="senha-guiche">
        <div style="display: flex">
          <div class="text-senha-guiche">Senha</div>
          <div class="text-senha-guiche">Guichê</div>
          <div class="text-senha-guiche">Status</div>
        </div>
      </div>
      <div class="fundo-branco">
        <br />
        <div class="space-between-password"
          v-for="last in latestCalls"
          :key="last.password"
        >
          <div class="fundo-senhas"
            v-if="last.attendance.type === 'Preferencial'"
          >
            <div v-if="last.status === 'Chamando'">
              <div class="texto-preferencial">
                <div class="last-called senha-alert">
                  {{ last.password.toUpperCase() }}
                </div>
                <div class="position-image">
                  <preferential-images />
                </div>
              </div>

              <div class="texto-guiche senha-alert">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="texto-status senha-alert">{{ last.status }}</div>
            </div>

            <div v-else-if="last.status === 'Em atendimento'">
              <div class="texto-preferencial">
                <div class="last-called">{{ last.password.toUpperCase() }}</div>
                <div class="position-image">
                  <preferential-images />
                </div>
              </div>

              <div class="texto-guiche">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="texto-status">{{ last.status }}</div>
            </div>
            <div v-else-if="last.status === 'Cancelado'">
              <div class="texto-preferencial">
                <div class="last-called">
                  <div class="canceled animate__heartBeat">
                    {{ last.password.toUpperCase() }}
                  </div>
                </div>
                <div class="position-image">
                  <preferential-images />
                </div>
              </div>
              <div class="texto-guiche-canceled animate__heartBeat">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="canceled-text-status animate__heartBeat">
                {{ last.status }}
              </div>
            </div>
            <div v-else-if="last.status === 'Ausente'">
              <div class="texto-preferencial">
                <div class="last-called ausente-text">
                  {{ last.password.toUpperCase() }}
                </div>
                <div class="position-image">
                  <preferential-images />
                </div>
              </div>

              <div class="texto-guiche-ausente">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="ausente-text-status">{{ last.status }}</div>
            </div>
            <div v-else>
              <div class="texto-preferencial">
                <div class="last-called senha-alert">
                  {{ last.password.toUpperCase() }}
                </div>
                <div class="position-image">
                  <preferential-images />
                </div>
              </div>
              <div class="texto-guiche">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="texto-status">{{ last.status }}</div>
            </div>
          </div>

          <div class="fundo-senhas"
            v-else-if="last.attendance.type === 'Convencional'"
          >
            <div v-if="last.status === 'Chamando'">
              <div class="texto-convencional">
                <div class="last-called senha-alert">
                  {{ last.password.toUpperCase() }}
                </div>
              </div>
              <div class="texto-guiche senha-alert">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="texto-status senha-alert">{{ last.status }}</div>
            </div>
            <div v-else-if="last.status === 'Cancelado'">
              <div class="texto-convencional">
                <div class="last-called">
                  <div class="canceled animate__heartBeat">
                    {{ last.password.toUpperCase() }}
                  </div>
                </div>
              </div>
              <div class="texto-guiche-canceled animate__heartBeat">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="canceled-text-status animate__heartBeat">
                {{ last.status }}
              </div>
            </div>

            <div v-else-if="last.status === 'Em atendimento'">
              <div class="texto-convencional">
                <div class="last-called">{{ last.password.toUpperCase() }}</div>
              </div>
              <div class="texto-guiche">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="texto-status">{{ last.status }}</div>
            </div>

            <div v-else-if="last.status === 'Ausente'">
              <div class="texto-convencional">
                <div class="ausente-text">
                  {{ last.password.toUpperCase() }}
                </div>
              </div>
              <div class="texto-guiche-ausente">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
              <div class="ausente-text-status">{{ last.status }}</div>
            </div>

            <div v-else>
              <div class="texto-convencional">
                <div class="last-called senha-alert">
                  {{ last.password.toUpperCase() }}
                </div>
              </div>
              <div class="texto-guiche">
                {{
                  last.guicheCaller
                    ? last.guicheCaller.toUpperCase()
                    : "desconhecido"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreferentialImages from "./PreferentialImages.vue";
import "animate.css";

export default {
  name: "LastCalled",
  components: { "preferential-images": PreferentialImages },
  props: ["latestCalls", "status"],
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap");

.texto-status {
  position: absolute;
  top: 30%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 25px;
  color: #000000;
  left: 385px;
  width: 220px;
}

.fundo-last-called {
  width: 700px;
}

.fundo {
  height: 500px;
  width: 700px;
  background-color: #c4d7ed;
  border-radius: 15px 15px;
  margin-top: 5px;
  margin-left: 100px;
  box-shadow: 1px 1px 1px #bfbfbf;
}

.texto-chamado {
  font-family: "Nunito", "Sans-serif";
  font-weight: bold;
  color: #4f4f4f;
  font-size: 40px;
}

.senha-guiche {
  height: 30px;
  width: 650px;
  background-color: #6598b8;
  margin: 0 auto;
}

.text-senha-guiche {
  display: inline-block;
  padding-left: 50px;
  padding-right: 90px;
  margin-top: 5px;
  font-family: "Nunito", "Sans-Serif";
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
}

.fundo-branco {
  display: inline-block;
  height: 350px;
  width: 650px;
  background-color: #f0eeed;
  margin: 0 auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
}

.fundo-senhas {
  display: inline-block;
  height: 65px;
  width: 610px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  position: relative;
}

.texto-preferencial {
  position: relative;
  padding-top: 2%;
  left: 3%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 28px;
  color: #000000;
}

.texto-convencional {
  position: relative;
  padding-top: 3%;
  left: 3%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 28px;
  color: #000000;
}

.texto-guiche {
  position: absolute;
  top: 28%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 28px;
  color: #000000;
  left: 210px;
}

.texto-guiche-canceled {
  position: absolute;
  top: 28%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 28px;
  color: #bf6363;
  left: 210px;
}

.space-between-password {
  margin-bottom: 17px;
}

.last-called {
  display: flex;
}

.position-image {
  padding-right: 490px;
}

.canceled {
  color: #bf6363;
}

.canceled-text-status {
  position: absolute;
  top: 20%;
  left: 385px;
  width: 220px;
  margin-top: 5px;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 25px;
  color: #bf6363;
}
.ausente-text {
  position: relative;
  width: 120px;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 28px;
  color: #fd8f00;
}
.texto-guiche-ausente {
  position: absolute;
  top: 28%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 28px;
  color: #fd8f00;
  left: 210px;
}
.ausente-text-status {
  position: absolute;
  top: 30%;
  font-family: "Roboto Mono", monospace;
  font-weight: bolder;
  font-size: 25px;
  color: #fd8f00;
  left: 385px;
  width: 220px;
}

.senha-alert {
  color: #fff0;
  animation: brilho 1s cubic-bezier(0.86, 0.01, 0.07, 0.76) infinite;
}

@keyframes brilho {
  50% {
    color: blue;
  }
}
</style>
